import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import Modal from 'react-modal';

import {
  getAllEvents,
  getLiveNowEvents,
  getUpcomingEvents,
} from '../../../scripts/dataHandlers';
import EventsItem from '../Events/EventsItem';
import { DateRangePicker } from 'react-date-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classes from '../Events.module.css';
import { ToastContainer, toast } from 'react-toastify';

Modal.setAppElement(document.body);

export default function EventsCategoryItem() {
  let { categoryName, organizationId, categoryId } = useParams();
  const [allEvents, setAllEvents] = useState(null);
  const [startDateValue, setStartDateValue] = useState(new Date());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [showDateTimeModal, setShowDateTimeModal] = useState(false);
  const [upcomingFilterApplied, setupcomingFilterApplied] = useState(true);

  const fetchOnlyUpcomingEvents = useCallback(async () => {
    const from = Date.now();
    let res;
    if (categoryId === 'upcomingEvent') {
      // it means theere is no category but contains events from "upcomingEvents or live events", we have to send different api requests
      res = await getUpcomingEvents(organizationId, 10);
    } else if (categoryId === 'liveEvent') {
      res = await getLiveNowEvents(organizationId);
    } else {
      res = await getAllEvents(organizationId, categoryId, 10, from);
    }
    console.log(res);
    setAllEvents(res.data.events);
  }, [categoryId, organizationId]);

  useEffect(() => {
    organizationId && categoryId && fetchOnlyUpcomingEvents();
  }, [organizationId, categoryId, fetchOnlyUpcomingEvents]);

  const removeAppliedFilter = () => {
    setupcomingFilterApplied(true);
    fetchOnlyUpcomingEvents();
  };
  const selectionRange = {
    startDate: startDateValue,
    endDate: endDateValue,
    key: 'selection',
  };
  const handleSelect = (ranges) => {
    console.log(ranges, 'ranges');
    setStartDateValue(ranges.selection.startDate);
    setEndDateValue(ranges.selection.endDate);
  };
  const handleCloseDialog = () => {
    setShowDateTimeModal(!showDateTimeModal);
  };

  const handleConfirm = async () => {
    if (startDateValue.getTime() === endDateValue.getTime()) {
      return toast.error('Start And End Date Cannot be same', {
        autoClose: 10000,
        position: 'top-center',
        theme: 'dark',
      });
    }

    let res;
    if (categoryId === 'upcomingEvent') {
      // it means theere is no category but contains events from "upcomingEvents", we have to send different api requests
      res = await getUpcomingEvents(
        organizationId,
        10,
        Date.parse(startDateValue),
        Date.parse(endDateValue)
      );
    } else if (categoryId === 'liveEvent') {
      res = await getLiveNowEvents(organizationId);
    } else {
      res = await getAllEvents(
        organizationId,
        categoryId,
        null,
        Date.parse(startDateValue),
        Date.parse(endDateValue)
      );
    }

    console.log(res);
    if (res.data.status === 'ok') {
      setAllEvents(res.data.events);
    }
    setStartDateValue(new Date());
    setEndDateValue(new Date());

    handleCloseDialog();
    setupcomingFilterApplied(false);
  };

  return (
    <>
      <div
        className={classes.Events_main}
        style={{ width: '80%', margin: '0 auto' }}
      >
        <div className={classes.Events_main_info}>
          <div className={`${classes.Events_main_info_header} font-400`}>
            <h2>{categoryName}</h2>
          </div>
          <div className={`${classes.Events_main_info_button} font-200`}>
            <button
              className={classes.Events_main_info_filter_addFilter}
              onClick={handleCloseDialog}
            >
              <FontAwesomeIcon
                icon='filter'
                size='1x'
                className={classes.filterIcon}
              />
              Filters
            </button>
            {!upcomingFilterApplied && (
              <button
                className={classes.Events_main_info_filter_removeFilter}
                onClick={removeAppliedFilter}
              >
                <FontAwesomeIcon
                  icon='filter'
                  size='1x'
                  className={classes.removeIcon}
                />
                Clear
              </button>
            )}
          </div>
        </div>
        {upcomingFilterApplied && categoryId !== 'liveEvent' && (
          <h2 style={{ color: '#0074d9', marginBottom: '30px' }}>
            Upcoming Events
          </h2>
        )}
      </div>
      {console.log(categoryId, upcomingFilterApplied)}
      <Modal
        isOpen={showDateTimeModal}
        contentLabel='Select Date'
        className={'modal'}
        overlayClassName={'overlay'}
        onRequestClose={handleCloseDialog}
      >
        <ToastContainer />
        <h2 className='font-600'>Filter Events Based on Start And End Date </h2>
        <div className={classes.modalItem}>
          {' '}
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            months={1}
            scroll={{ enabled: true }}
            direction='vertical'
            preventSnapRefocus={true}
            calendarFocus='backwards'
            showSelectionPreview={true}
            moveRangeOnFirstSelection={true}
          />
          <div className={classes.modalItem_button}>
            <button
              className={classes.modalItem_button_confirm}
              onClick={handleConfirm}
            >
              Confirm
            </button>
            <button
              onClick={handleCloseDialog}
              className={classes.modalItem_button_cancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <EventsItem
        allEvents={allEvents}
        heading={categoryName}
        organizationId={organizationId}
        categoryId={categoryId}
        // need to set prooperty to true so we can add calendar icon herej
        hideViewAll={true}
      />
    </>
  );
}
