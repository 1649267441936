import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classes from './DetailsShare.module.css';
import HelmetMetaData from '../ShareSocialMedia/HelmetMetaData';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { organization } from '../../configs/config_settings.json';
import { useState, useRef, useEffect } from 'react';
async function getUrFromService(props) {
  // The real implementation would make a network call here.
  // await new Promise((resolve) => setTimeout(resolve, 4000));
  return props.chosenItem.thumbnailSmall
    ? props.chosenItem.thumbnailSmall
    : props.chosenItem.thumbnailMedium;
}

const DetailsShare = (props) => {
  const [url, setUrl] = useState('none');
  const shareButton = useRef(null);
  // / Provide an onClick handler that asyncronously fetches the url and sets it in the state.
  const onClick = async () => {
    // Be sure to check for the "none" state, so we don't trigger an infinite loop.
    if (url === 'none') {
      const newUrl = await getUrFromService(props);
      setUrl(newUrl);
    }
  };
  const onClick1 = async () => {
    // Be sure to check for the "none" state, so we don't trigger an infinite loop.
    if (url === 'none') {
      const newUrl = await getUrFromService(props);
      setUrl(newUrl);
    }
  };

  // Whenever "url" changes and we re-render, we manually fire the click event on the button, and then re-set the url.
  useEffect(() => {
    if (url !== 'none') {
      shareButton.current?.click();
      setUrl('none');
    }
  }, [url, shareButton]);
  console.log('propsShare', props);
  return (
    <div className={`${classes.details_share}`}>
      <HelmetMetaData image={url} />

      <FacebookShareButton
        // url={window.location.href}
        quote={`${
          props.chosenItem?.serie?.title ||
          props.chosenItem?.title ||
          props.chosenItem?.name
        }\n\n
        ${
          props.chosenItem?.serie?.description ||
          props.chosenItem?.ingress ||
          props.chosenItem?.description
        }
        `}
        hashtag={`#${
          organization.name ||
          props.chosenItem?.title ||
          props.chosenItem?.serie?.title ||
          props.chosenItem?.name
        }`}
        ref={shareButton}
        // Disable calling the dialog if we don't have a url yet.
        openShareDialogOnClick={url !== 'none'}
        url={window.location.href}
        onClick={onClick}
        className={classes.socialMediaButton}
      >
        <FontAwesomeIcon
          icon={['fab', 'facebook']}
          className={`${classes.shareBTN} font-800`}
        />
      </FacebookShareButton>

      {/* <TwitterShareButton
        title={`${
          props.chosenItem?.serie?.title ||
          props.chosenItem?.title ||
          props.chosenItem?.name
        }\n`}
        hashtags={[
          ...(props.chosenItem?.title ? [props.chosenItem.title] : []),
          ...(props.chosenItem?.serie?.title
            ? [props.chosenItem.serie.title]
            : []),
          ...(props.chosenItem?.name ? [props.chosenItem.name] : []),
          ...(organization.name ? [organization.name] : []),
        ]}
        ref={shareButton}
        // Disable calling the dialog if we don't have a url yet.
        openShareDialogOnClick={url !== 'none'}
        url={url}
        onClick={onClick1}
        //via='Somenamehere, shows up like @something in end of tweet'
        className={classes.socialMediaButton}
      >
        <FontAwesomeIcon
          icon={['fab', 'twitter']}
          className={`${classes.shareBTN} font-800`}
        />
      </TwitterShareButton> */}
    </div>
  );
};

export default DetailsShare;

/*
<Link
        to={{
          pathname: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${props.chosenItem.name}`,
        }}
        target='_blank'
        rel='noreferrer noopener'
      >
        <FontAwesomeIcon
          icon={['fab', 'facebook']}
          className='shareBTN shareFB font-800'
        />
      </Link>

      <Link
        to={{
          pathname: `https://twitter.com/share?text=${props.chosenItem.name} url=${window.location.href}&image=${props.chosenItem.coverImageSmall}`,
        }}
        target='_blank'
        rel='noreferrer noopener'
      >
        <FontAwesomeIcon
          icon={['fab', 'twitter']}
          className='shareBTN shareTW font-800'
        />
      </Link>
*/
