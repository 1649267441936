import React, { useEffect, useState } from 'react';
import EventsItem from '../Events/EventsItem';

import { useTranslation } from 'react-i18next';
import {
  getUpcomingEvents,
  getUpcomingEventsBeta,
} from '../../../scripts/dataHandlers';

export default function UpcomingEvents(props) {
  const [allUpcomingEvents, setAllUpcomingEvents] = useState(null);
  const { t } = useTranslation();

  console.log(props);
  useEffect(() => {
    const runFunction = async () => {
      try {
        // const res1 = await getUpcomingEvents(props.organizationId);
        const res1 = await getUpcomingEvents(props.organizationId, props.limit);

        console.log(`res1 upcoming events`, res1);

        // we have to get event based on categoryID

        if (res1.data.status === 'ok') {
          // setAllUpcomingEvents(res1.data.events.slice(0, 3));
          setAllUpcomingEvents(res1.data.events);
        }
      } catch (error) {
        console.log(error);
      }
    };

    props.organizationId && runFunction();
  }, [props.organizationId, props.limit]);
  return (
    // If it includes props(upcomingEventCategory) , it means it wont have any category id or name and we have to fetch filter data and route accordingly
    allUpcomingEvents && (
      <EventsItem
        allEvents={allUpcomingEvents}
        heading={t('eventsCategory.upcomingEventsTitle')}
        upcomingEventCategory={true}
        organizationId={props.organizationId}
        homepageContainer={props.homepageContainer}
        extraClassname={props.extraClassname}
        styles={props.styles}
      />
    )
  );
}
